import { FC, useCallback } from 'react'
import { Helmet } from 'react-helmet-async'
import toast from 'react-hot-toast'
import { Api } from '../../api'
import { Button } from '../../components/Button'
import { Logo } from '../../components/Logo'
import { Text } from '../../components/Text'
import {
  Field,
  Form,
  FormProps,
  Input,
  PasswordInput,
} from '../../components/form'
import { Validation } from '../../components/form/validation'
import { useSteps } from '../../hooks/steps'
import { useSwitch } from '../../hooks/switch'
import s from './ResetPassword.module.css'

const ResetPassword: FC = () => {
  const { step, stepOn } = useSteps(4, token ? 2 : 0)

  const { on: pending, open: startPending, close: stopPending } = useSwitch()

  const handleStep0Submit: FormProps['onSubmit'] = useCallback(
    async (values) => {
      startPending()

      try {
        await Api.post('auth/forgotPassword', { email: values.email })
        stepOn()
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      } finally {
        stopPending()
      }
    },
    [startPending, stepOn, stopPending]
  )

  const handleStep3Submit: FormProps['onSubmit'] = useCallback(
    async (values) => {
      startPending()

      try {
        await Api.post(
          'auth/resetPassword',
          { password: values.password },
          { token }
        )
        stepOn()
        setTimeout(() => {
          window.location.replace(process.env.REACT_APP_WEB_PORTAL_ORIGIN!)
        }, 5000)
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      } finally {
        stopPending()
      }
    },
    [startPending, stepOn, stopPending]
  )

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <div className={s.root}>
        <main className={s.main}>
          <div className={s.logo}>
            <Logo width="10rem" />
          </div>
          {step === 0 && (
            <>
              <div className={s.text}>
                <div className={s.title}>
                  <Text type="title" bold>
                    Forgot password?
                  </Text>
                </div>
                <Text>
                  Please enter your email below to receive a link to reset your
                  password.
                </Text>
              </div>
              <Form validation={step0Validation} onSubmit={handleStep0Submit}>
                <Field
                  name="email"
                  label="Email address"
                  control={
                    <Input type="email" placeholder="Email address" autoFocus />
                  }
                />
                <Button type="submit" block pending={pending}>
                  Send Password Reset Link
                </Button>
              </Form>
            </>
          )}
          {step === 1 && (
            <>
              <div className={s.text}>
                <div className={s.title}>
                  <Text type="title" bold>
                    Check your email
                  </Text>
                </div>
                <Text>
                  An email will be sent if the account exists. Please check your
                  inbox or spam/junk folder for a reset password email.
                </Text>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className={s.text}>
                <div className={s.title}>
                  <Text type="title" bold>
                    Reset your password
                  </Text>
                </div>
              </div>
              <Form validation={step3Validation} onSubmit={handleStep3Submit}>
                <Field
                  name="password"
                  label="New password"
                  control={
                    <PasswordInput
                      placeholder="Create a new password"
                      autoFocus
                    />
                  }
                  tip="Use 8 or more characters with a mix of letters, numbers & symbols"
                />
                <Field
                  name="password-again"
                  label="Confirm password"
                  control={<PasswordInput />}
                />
                <Button type="submit" block pending={pending}>
                  Reset Password
                </Button>
              </Form>
            </>
          )}
          {step === 3 && (
            <>
              <div className={s.text}>
                <div className={s.title}>
                  <Text type="title" bold>
                    Password reset
                  </Text>
                </div>
                <Text>You can now login with your new password.</Text>
                <div className={s.tip}>
                  <Text type="text-x-small">
                    You will now be re-directed to login.
                  </Text>
                </div>
              </div>
            </>
          )}
        </main>
      </div>
    </>
  )
}

const token = new URL(window.location.href).searchParams.get('token')

const step0Validation: Validation = {
  email: [
    {
      test: 'valueMissing',
      message: 'Email address required',
    },
    {
      test: 'typeMismatch',
      message: 'Invalid email address',
    },
  ],
}

const step3Validation: Validation = {
  password: [
    {
      test: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      message:
        'Use 8 or more characters with a mix of letters, numbers & symbols',
    },
  ],
  'password-again': [
    {
      test: 'valueMissing',
      message: 'Confirm password required',
    },
    {
      test: (value, values) => value === values.password,
      message: "Passwords don't match, try again.",
    },
  ],
}

export { ResetPassword }
