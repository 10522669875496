const dedup = <Item>(array: Item[], key?: keyof Item): Item[] => {
  if (!key) {
    return [...new Set(array)]
  }

  const deduppedKeys = dedup(array.map((item) => item[key]))
  return deduppedKeys.map(
    (deduppedKey) => array.find((item) => item[key] === deduppedKey)!
  )
}

const createBooleanDataset = (map: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(map).map(([key, value]) => [
      `data-${key}`,
      value ? '' : undefined,
    ])
  )
}

const isAbsoluteUrl = (uri: any) => {
  try {
    new URL(uri)
    return true
  } catch (error) {
    return false
  }
}

const formatMobile = (countryCode: string, number: string) => {
  const trimmedNumber = number.replace(/^0/, '')
  return trimmedNumber && `${countryCode}${trimmedNumber}`
}

class Countries {
  static raw: {
    name: string
    code: string
    bn?: {
      name: string
      field: string
    }
    flag: string
    areaCode: string
  }[] = [
    {
      name: 'Australia',
      code: 'AU',
      bn: {
        name: 'ABN',
        field: 'abn',
      },
      flag: '🇦🇺',
      areaCode: '61',
    },
    {
      name: 'India',
      code: 'IN',
      flag: '🇮🇳',
      areaCode: '91',
    },
    {
      name: 'New Zealand',
      code: 'NZ',
      bn: {
        name: 'NZBN',
        field: 'business_registration_number',
      },
      flag: '🇳🇿',
      areaCode: '64',
    },
    {
      name: 'United Kingdom',
      code: 'UK',
      flag: '🇬🇧',
      areaCode: '44',
    },
    {
      name: 'United States',
      code: 'US',
      flag: '🇺🇸',
      areaCode: '1',
    },
  ]

  static findByCode(code: unknown) {
    return this.raw.find((item) => item.code === code)
  }
}

export { Countries, createBooleanDataset, dedup, formatMobile, isAbsoluteUrl }
